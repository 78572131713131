/* eslint-disable */
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (home.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/ref/:place/:id",
    name: "Ref",
    component: () => import(/* webpackChunkName: "ref" */ "@/views/Ref.vue"),
  },
  {
    path: "/login/join",
    name: "Join",
    component: () => import(/* webpackChunkName: "join" */ "@/views/Join.vue"),
  },
  {
    path: "/login/new-password",
    name: "AuthNewPassword",
    component: () =>
      import(/* webpackChunkName: "authNewPassword" */ "@/views/AuthNewPassword.vue"),
  },
  {
    path: "/login/password-recovery",
    name: "PasswordRecovery",
    component: () =>
      import(/* webpackChunkName: "passwordRecovery" */ "@/views/PasswordRecovery.vue"),
  },
  {
    path: "/login/confirm-email",
    name: "ConfirmEmail",
    component: () => import(/* webpackChunkName: "confirmEmail" */ "@/views/ConfirmEmail.vue"),
  },
  {
    path: "/login/verify-email",
    name: "VerifyEmail",
    component: () => import(/* webpackChunkName: "verifyEmail" */ "@/views/VerifyEmail.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import(/* webpackChunkName: "profile" */ "@/views/Profile.vue"),
  },
  {
    path: "/business-volume",
    name: "Business volume",
    component: () => import(/* webpackChunkName: "businessVolume" */ "@/views/BusinessVolume.vue"),
  },
  {
    path: "/binary-tree/:id?",
    name: "BinaryTree",
    component: () => import(/* webpackChunkName: "binaryTree" */ "@/views/BinaryTree.vue"),
  },
  {
    path: "/linear-tree",
    name: "LinearTree",
    component: () => import(/* webpackChunkName: "linearTree" */ "@/views/LinearTree.vue"),
  },
  {
    path: "/direct-bonus",
    name: "DirectBonus",
    component: () => import(/* webpackChunkName: "directBonus" */ "@/views/DirectBonus.vue"),
  },
  {
    path: "/matching",
    name: "Matching",
    component: () => import(/* webpackChunkName: "matching" */ "@/views/Matching.vue"),
  },
  {
    path: "/connect-wallet",
    name: "ConnectWallet",
    component: () => import(/* webpackChunkName: "connectWallet" */ "@/views/ConnectWallet.vue"),
  },
  {
    path: "/packages",
    name: "Packages",
    component: () => import(/* webpackChunkName: "packages" */ "@/views/Packages.vue"),
  },
  {
    path: "/career",
    name: "Career",
    component: () => import(/* webpackChunkName: "career" */ "@/views/Career.vue"),
  },
  {
    path: "/openFastestCareer",
    name: "OpenFastestCareer",
    component: () =>
      import(/* webpackChunkName: "OpenFastestCareer" */ "@/views/OpenFastestCareer.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "@/views/404.vue"),
  },
  {
    path: "/openSupport",
    name: "OpenSupport",
    component: () => import(/* webpackChunkName: "OpenSupport" */ "@/views/OpenSupport.vue"),
  },
  {
    path: "/openUpdates",
    name: "OpenUpdates",
    component: () => import(/* webpackChunkName: "OpenUpdates" */ "@/views/OpenUpdates.vue"),
  },
  {
    path: "/nodes",
    name: "Nodes",
    component: () => import(/* webpackChunkName: "nodes" */ "@/views/Nodes.vue"),
  },
  {
    path: "/stake",
    name: "Stake",
    component: () => import(/* webpackChunkName: "nodes" */ "@/views/Stake.vue"),
  },
  {
    path: "/openStake",
    name: "OpenStake",
    component: () => import(/* webpackChunkName: "nodes" */ "@/views/OpenStake.vue"),
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: () => import(/* webpackChunkName: "statistics" */ "@/views/Statistics.vue"),
  },
  {
    path: "/promotion",
    name: "Promotion",
    component: () => import(/* webpackChunkName: "promotion" */ "@/views/Promotion.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
