/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { AxiosResponse } from 'axios';

import { path } from 'rambda';

import { defineModule } from 'direct-vuex';
import { modActionCtx, modGetterCtx } from '@/store/index';
import { api } from '@/utils/apiInstance';

import {
  basicOfficeFetch, getFetchState, FetchStateInterface, getFetchMutations,
} from '@/store/storeUtils';
import {
  PENDING, REJECTED,
} from '@/utils/statuses';

export interface UserDashboard {
  binary: {
    left: number;
    right: number;
  },
  end_date: number | string;
  total_earned_usdt: number | string;
  fc_balance_usdt: number | string;
  flcToUsdtRate: number | string;
  rank: {
    expected_rank: number | string,
    first_line: number | string,
    max_rank_left: number | string,
    max_rank_left_user: {
      rank: number | string,
      user: {
        avatar: number | string,
        email: number | string,
        id: number | string,
        name: number | string,
      },
    },
    max_rank_right: number | string,
    max_rank_right_user: {
      rank: number | string,
      user: {
        avatar: number | string,
        email: number | string,
        id: number | string,
        name: number | string,
      },
    },
    rank: {id: number | string, title: number | string},
    sales_left: number | string,
    sales_right: number | string,
    users_left: any,
    users_right: any,
  },
  total_earned: number;
  fc_balance: number;
}

export type DashboardResponse = AxiosResponse<{ data: UserDashboard }>;

const userDashboardStore = defineModule({
  namespaced: true,
  state: {
    ...getFetchState<DashboardResponse>(),
  } as FetchStateInterface<DashboardResponse>,

  getters: {
    data(...args): UserDashboard | undefined {
      const { state } = getterCtx(args);

      const result = path('data.data', state.response) as UserDashboard;

      return result;
    },
  },
  mutations: {
    ...getFetchMutations(),
  },
  actions: {

    async fetch(ctx) {
      const { commit, state } = actionCtx(ctx);

      if (state.fetchState === PENDING) {
        return;
      }

      await basicOfficeFetch({
        method: api.office.sendPost,
        props: {
          url: '/user/dashboard',
          data: {},
        },
        setState: commit.SET_STATE,
        // update state
        callback: (fetchState) => {
          if (fetchState === REJECTED) {
            console.error('Fetch error in userDashboardStore.fetch, status: ', REJECTED);
          }
        },
      });
    },
  },
});

export default userDashboardStore;
const getterCtx = (args: [any, any, any, any]) => modGetterCtx(args, userDashboardStore);
const actionCtx = (ctx: any) => modActionCtx(ctx, userDashboardStore);
